/* App.css */
input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px calc(var(--unit) * 2) #222 inset !important; /* Change to your desired color */
    background-clip: content-box !important;
}

:root {
  --unit: 20px;
  --color-accent-primary: #ffa500;
  --color-accent-secondary: #ffd000;
  --color-sidebar-plus: #777;

  --patternbg-image: url('images/tile-diagonal.png');
  --patternbg-size: 30px;
  --patternbg-color: #222;

  --font-size-default: calc(var(--unit) * 0.75);
  --font-size-small: calc(var(--unit) * 0.625);
  --font-size-tiny: calc(var(--unit) * 0.5);
  --font-size-title: calc(var(--unit) * 1);
}

::-webkit-scrollbar-track {
  background: rgba(20, 20, 20, 1); /* Black background */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Light grey handle */
}

html {
  scrollbar-color: #555 rgba(20, 20, 20, 1); /* Light grey handle and black background */
}

body {
  position: relative;
  background-color: rgba(20, 20, 20, 1);
  color: #ccc;
  text-align: center;
  font-size: var(--font-size-default);
}

select {
  background-color: rgba(0, 0, 0, 0);
  color: #ccc;
  border: none;
  border-color: #555;
  outline: none;
  font-size: var(--font-size-default);
  border-radius: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.5);
  font-weight: bold;
}

input {
  appearance: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  border-radius: calc(var(--unit) * 0.25);
  font-size: var(--font-size-default);
  height: calc(var(--unit) * 2);
  padding-inline: calc(var(--unit));
}

input, button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: calc(var(--unit) * 2);
  font-size: var(--unit);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

button {
  background-color: var(--color-accent-primary);
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color var(--animation-speed-standard) ease;
  color: black;
  transition: background-color 0.3s;
  height: calc(var(--unit) * 2);
  padding-inline: var(--unit);
  box-sizing: border-box;
}

form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.5);
}d

select:hover {
  background: rgba(102, 102, 102, 0.25);
}

.songbooksviewer {
  display: flex;
  height: 100dvh;
  overflow: hidden;
}

.songbooksviewer-content {
  display: flex;
  flex: 1;
}

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 250px;
  color: #ccc;
  background-image: var(--patternbg-image);
  background-size: var(--patternbg-size);
  background-color: var(--patternbg-color);
  border-right: 1px solid black;
  justify-content: space-between;
  user-select: none;
  transition: width 0.15s linear;
  text-wrap: nowrap;
  text-align: left;
}

.sidebar.hidden {
  width: 0;
  min-width: 0;
}

h2 {
  font-size: var(--font-size-small);
  color: #777;
  margin-top: var(--unit);
  padding-left: calc(var(--unit) * 0.5);
}

.sidebar-category:first-of-type {
  padding-top: 0;
}

.sidebar-category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-small);
  font-weight: bold;
  color: #777;
  padding-top: var(--unit);
  padding-bottom: calc(var(--unit) * 0.5);
  padding-left: calc(var(--unit) * 0.5);
}

.category-content {
  overflow: hidden;
}

.sidebar-category-chevron {
  opacity: 0;
  padding-inline: calc(var(--unit) * 0.5);
}

.sidebar-category:hover .sidebar-category-chevron {
  opacity: 1;
}

.sidebar-category-chevron:active {
  color: white;
}

.sidebar-toggle-container {
  position: fixed;
  display: flex;
  align-items: center;
  min-height: calc(var(--unit) * 3);
  z-index: 2;
}

.chevron {
  cursor: pointer
}

.sidebar button {
  background: none;
  color: var(--color-accent-primary);
}

.sortable-songbook {
  display: flex;
  align-items: center;
  padding: calc(var(--unit) * 0.25) 0;
  border-radius: calc(var(--unit) * 0.25);
}

.sortable-songbook:hover {
  background-color: rgba(102, 102, 102, 0.5);
  color: white; /* Change icon color to white */

}

.list-is-dragging:hover {
  background-color: inherit !important;
  cursor: default !important;
}


.sortable-songbook.selected {
  background-color: #777; /* Highlight background */
  color: white; /* Change icon color to white */

}

.sortable-songbook.selected .list-icon {
  color: white; /* Change icon color to white */
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: calc(var(--unit) * 0.25) 0;
  border-radius: calc(var(--unit) * 0.25);
}

.sidebar li:hover {
  background-color: rgba(102, 102, 102, 0.5);
  color: white;
}

.sidebar li.selected {
  background-color: #777; /* Highlight background */
  color: white;
}

.sidebar li.selected .list-icon {
  color: white; /* Change icon color to white */
}

.sidebar-footer {
    display: flex;
    justify-content: left;
    align-items: center; 
    padding: calc(var(--unit) * 0.5);
    box-sizing: border-box;
    overflow: hidden;
}

.sidebar-hanchnetlogo {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sidebar-hanchnetlogo svg {
  color: #333;
  width: 40%;
}

.sidebar-toolbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(var(--unit) * 2);
}

.toolbar-button.account {
  margin: 0;
}

.toolbar-button.togglesidebar {
  margin: 0;
}

.toolbar-button.back {
  margin: 0 var(--unit) 0 0;
}

.share-learnmore  {
  color: #ccc;
  font-size: var(--font-size-small);
  padding: 0;
}

.sidebar-hanchnetlogo svg:hover {
  color: #777;
}

.list-icon {
  min-width: calc(var(--unit) * 2);
  color: var(--color-accent-primary);
}

.list-icon-gray {
  color: #777;
}

.new-songbook {
  color: #777;
}

.list-icon-new {
  color: var(--color-sidebar-plus);
}

.new-songbook:hover {
  color: #fff;
  --color-sidebar-plus: var(--color-accent-primary);
}

.shared-songbooks li:hover {
    background: none;
}

.shared-songbook {
  color: #777;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(var(--unit) * 3);
  color: #ccc;
  overflow: hidden;
  padding-inline: calc(var(--unit) * 0.5);
  width: auto;
}

.songbooks-logo {
  width: 100%;
}

.sidebar-lists {
  display: flex;
  flex-direction: column;
  cursor: default;
  padding: 0 calc(var(--unit) * 0.5);
  overflow-y: auto;
  text-align: left;
}

.sidebar li .songbook-title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.view {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.view-toolbar {
  display: flex;
  min-height: calc(var(--unit) * 3);
  justify-content: space-between;
  align-items: center;
  background-color: #222;
  padding-inline: var(--unit);
  text-wrap: nowrap;
  border-bottom: 1px solid black;
}

.view-toolbar h1 {
  margin: 0 calc(var(--unit) * 0.5) 0 0;
  font-size: var(--font-size-title);
}

.toolbar-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toolbar-button {
  padding: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.75);
  border-radius: calc(var(--unit) * 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1; /* Ensure the width matches the height */
}

.toolbar-button.sidebar {
  margin-inline: 0;
}

.toolbar-button.view {
  margin-inline: 0;
  border-radius: calc(var(--unit) * 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1; /* Ensure the width matches the height */
}

.toolbar-button-togglesidebar {
  border-radius: calc(var(--unit) * 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(var(--unit) * 0.75);
  margin-right: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
}

.toolbar-button-togglesidebar:hover {
  background-color: rgba(102, 102, 102, 0.25);
}

.toolbar-button:hover {
  background: rgba(102, 102, 102, 0.25);
  color: white;
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-stack {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.title-stack.margin-adjust {
  margin-left: calc(var(--unit) * 1.5);
}

.subtitle {
  font-size: var(--font-size-small);
  color: #777;
  margin: 0;
  margin-top: calc(var(--unit) * 0.125);
}

.sortable-titles {
  min-height: calc(var(--unit) * 1.5);
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  border-bottom: 1px solid #111;
  background-color: #1b1b1b;
  color: #777;
  user-select: none;
}

.sortable-titles h2 {
  position: relative;
  margin: 0;
  padding: 0 calc(var(--unit) * 0.5);
  font-weight: normal;
}

.sortable-title {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: min-content;
  height: 100%;
  box-sizing: border-box;
}

.sortable-title.fixed {
  max-width: min-content;
  min-width: min-content;
}

.sortable-title:hover {
  background-color: #222;
}

.sortable-title-divider {
  min-width: 1px;
  background-color: #333;
  height: 50%;
}

.sortable-title-handle {
  min-width: 2px;
  border-right: 1px solid #333;
  border-left: 1px solid #333;
  height: 50%;
}

.sortable-title-handle:hover {
  border-right: 1px solid #555;
  border-left: 1px solid #555;
  cursor: ew-resize;
}

.sortable-title.settings {
  min-width: calc(var(--unit) * 1.5);
  width: calc(var(--unit) * 1.5);
  padding: 0;
  justify-content: center;
}

.sort-direction {
  padding-right: calc(var(--unit) * 0.5); 
}

.sort-button.hidden {
  visibility: hidden;
  min-width: calc(var(--unit) * 0.5);  
}

.view-content h1 {
  margin-top: 0;
}

.view-content {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.view-content-emptyspace {
  flex: 1;
  height: auto;
  width: 100%;
  padding-bottom: var(--unit);
  box-sizing: border-box;
}

.item-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #222;
  min-height: calc(var(--unit) * 2);
}

.item-container:hover {
    background: #222;
}

.item {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  cursor: default;
}

.item-primary-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: calc(var(--unit) * 5);
}

.item-secondary-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: var(--font-size-small);
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  min-width: min-content;
  height: 100%;
}

.item-secondary-info.string {
  text-overflow: ellipsis;
  min-width: calc(var(--unit) * 3);
    justify-content: inherit;
}

.secondary-content {
  margin-inline: calc((var(--unit) * 0.25) + 1px);
  padding: calc(var(--unit) * 0.25);
  border-radius: calc(var(--unit) * 0.25);
;
}

.secondary-content:hover {
  background-color: #333;
}

.align-first-column {
  padding-inline: calc(var(--unit) * 0.5);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #000;
  padding: var(--unit);
  border-radius: 5px;
  width: 300px;
  max-width: 80%;
}

.modal-content h2 {
  margin-top: 0;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.25);
  background-color: rgba(51, 51, 51, 1);
  border: 1px solid #777;
  z-index: 1000;
  min-width: calc(var(--unit) * 10);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: calc(var(--unit) * 0.25);
  padding: calc(var(--unit) * 0.25);
}

.context-menu button {
  display: flex;
  padding-inline: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  text-align: left;
  background: none;
  border: none;
  cursor: default;
  border-radius: 3px;
  justify-content: space-between;
  color: white;
  font-size: var(--font-size-default);
  font-weight: normal;
  width: 100%;
  height: auto;
}

.context-menu button:hover {
  background-color: #777;
}

.context-menu button:disabled {
  background-color: none;
  color: #aaa;
}

.context-menu button:disabled:hover {
  background: none;
}

.context-menu .active {
  background-color: #777;
}

.fullpage-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  font-size: calc(var(--unit) * 2);
}

.menu-separator {
  height: 1px;
  background-color: #777;
}

.menu-label {
  display: flex;
  flex-direction: row;
}

.checkable-space {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-width: calc(var(--unit)a);
  width: calc(var(--unit));
}

.time-signature-format {
  font-size: var(--font-size-tiny);
}

.select-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 0.5);
  padding: calc(var(--unit) * 0.25);
  margin-inline: calc(var(--unit) * 0.25);
  font-size: var(--font-size-title);
  font-weight: bold;
  border-radius: calc(var(--unit) * 0.25);
  color: #ccc;
}

.select-view:hover {
  color:#fff
}

.dev-login-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.dev-login-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  margin: auto;
  background-color: #333;;
  border-radius: var(--unit);
  padding: var(--unit);
}

.row {
  display: flex;
  flex-direction: row;
}